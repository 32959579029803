<template>
  <b-overlay :show="formShow" rounded="true" no-fade style="height:100vh!important">
    <div>
      <b-card class="mt-2">
        <b-row class="mb-2">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" style="font-size: 1.2em;">SUMMARY EXPLANATION</th>
              </tr>
            </thead>
          </table>
        </b-row>

        <div class="m-5">
          <vue-slider :min="0" :max="4" :tooltip="'always'" :dot-options="dotOptions" v-model="ranges" :interval="0.01" />
        </div>

        <b-alert variant="info" show>
          <div class="alert-body">
            <span> Please type <strong class="bg-danger p-25 text-white">#domainScore#</strong> inside the sentence in order to add the calculated score.</span>
          </div>
        </b-alert>

        <b-row class="mb-3 ml-2 mr-2" v-if="rangeDatas.length > 0">
          <b-col cols="12" class="mt-2">
            <label for="textarea-default">{{ rangeDatas[0].range }} - {{ rangeDatas[1].range }} summary explanation</label>
            <b-form-textarea id="textarea-default" v-model="rangeDatas[0].title" rows="2" />
          </b-col>

          <b-col cols="12" class="mt-2">
            <label for="textarea-default">{{ rangeDatas[1].range }} - {{ rangeDatas[2].range }} summary explanation</label>
            <b-form-textarea id="textarea-default" v-model="rangeDatas[1].title" rows="2" />
          </b-col>
          <b-col cols="12" class="mt-2">
            <label for="textarea-default">{{ rangeDatas[2].range }} - {{ rangeDatas[3].range }} summary explanation</label>
            <b-form-textarea id="textarea-default" v-model="rangeDatas[2].title" rows="2" />
          </b-col>
          <!-- <b-col cols="12">
            <label for="textarea-default">{{ rangeDatas[3].range }} - {{ rangeDatas[4].range }} summary explanation</label>
            <b-form-textarea id="textarea-default" v-model="rangeDatas[3].title" rows="2" />
          </b-col> -->
          <b-col cols="12" class="mt-2">
            <label for="textarea-default">{{ rangeDatas[3].range }} - {{ rangeDatas[4].range }} summary explanation</label>
            <b-form-textarea id="textarea-default" v-model="rangeDatas[3].title" rows="2" />
          </b-col>
        </b-row>

        <b-button type="submit" @click="updateRange" variant="warning" class="mr-3 float-right">
          Update
        </b-button>
      </b-card>
    </div>
  </b-overlay>
</template>
<script>
import { BFormGroup, BTable, BRow, BCol, BFormSelect, BPagination, BIcon, BSkeletonIcon, BFormRating } from 'bootstrap-vue';
import settingsStoreModule from '../../settingsStoreModule';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import _ from 'lodash';
import VueSlider from 'vue-slider-component';

import axiosIns from '@/libs/axios';

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BIcon,
    BFormRating,
    BSkeletonIcon,
    ValidationProvider,
    ValidationObserver,
    VueSlider,
  },
  data() {
    return {
      edit: null,
      levelName: null,
      editLevelName: '',
      rangeDatas: [],
      ranges: [],
      dir: 'ltr',
      formShow: false,
      editingGradeLId: 0,

      dotOptions: [
        {
          disabled: true,
        },
        {
          disabled: false,
        },
        {
          disabled: false,
        },
        {
          disabled: false,
        },
        {
          disabled: true,
        },
        // {
        //   disabled: true,
        // },
      ],
    };
  },
  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },
  computed: {},
  watch: {
    ranges(val) {
      this.rangeDatas[0].range = val[0];
      this.rangeDatas[1].range = val[1];
      this.rangeDatas[2].range = val[2];
      this.rangeDatas[3].range = val[3];
      // this.rangeDatas[4].range = val[4];
    },
  },
  methods: {
    getSummaryList(id) {
      store
        .dispatch('settings/fetchsummaryList', id)
        .then((res) => {
          res.data.title.forEach((element) => {
            this.ranges.push(element.range);
          });
          res.data.title.id = 0;
          this.rangeDatas = res.data.title;

          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },
    updateRange() {
      this.formShow = true;

      store
        .dispatch('settings/summaryUpdate', { id: this.$route.params.id ? this.$route.params.id : 0, title: this.rangeDatas })
        .then((res) => {
          this.formShow = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Updated Successful',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
          // router.push({ name: 'cpat-list' });
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },
  },
  created() {
    this.formShow = true;

    this.getSummaryList(this.$route.params.id ? this.$route.params.id : 0);
  },
};
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>
